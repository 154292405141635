import * as Portal from '@radix-ui/react-portal';

import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const style = {
	animation: {
		right: 'animate-drawer-right',
		left: 'animate-drawer-left',
	},
	orientation: {
		right: `flex w-full sm:w-96 h-full right-0 mx-0 my-0 absolute focus:outline-none `,
		left: `flex w-full sm:w-3/4 h-full left-0 mx-0 my-0 absolute focus:outline-none `,
	},
	body: `flex-shrink flex-grown overflow-auto`,
	headerTitle: `text-2xl md:text-3xl font-light`,
	content: `relative flex flex-col bg-v2-off-white pointer-events-auto w-full`,
	header: `items-start justify-between border-b border-gray-300`,
	// drawers need to go over chat buttons etc... gorgias chat is z-index 2147483000 ?????
	container: `fixed top-0 left-0 z-[2147483001] w-full h-full m-0`,
	overlay: `fixed top-0 left-0 z-30 w-screen h-screen bg-black opacity-50`,
	footer: `flex flex-wrap items-center justify-center p-3 mt-auto  border-t-2 border-v2-brown-darker`,
};

export default function Drawer({ children, isOpen, position, toggle }) {
	const ref = React.useRef(null);
	const container = React.useRef(null);

	// close drawer on click outside
	React.useEffect(() => {
		const handleOutsideClick = (event) => {
			if (!ref.current?.contains(event.target)) {
				if (!isOpen) return;
				toggle(false);
			}
		};
		container.current?.addEventListener('click', handleOutsideClick);
		return () => container.current?.removeEventListener('click', handleOutsideClick);
	}, [isOpen, ref, container, toggle]);

	// close drawer when you click on "ESC" key
	React.useEffect(() => {
		const handleEscape = (event) => {
			if (!isOpen) return;

			if (event.key === 'Escape') {
				toggle(false);
			}
		};
		document.addEventListener('keyup', handleEscape);
		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen, toggle]);

	// hide scrollbar and prevent body from moving when drawer is open
	//put focus on drawer dialogue
	React.useEffect(() => {
		if (!isOpen) return;

		ref.current?.focus();

		const html = document.documentElement;
		const scrollbarWidth = window.innerWidth - html.clientWidth;

		html.style.overflow = 'hidden';
		html.style.paddingRight = `${scrollbarWidth}px`;

		return () => {
			html.style.overflow = '';
			html.style.paddingRight = '';
		};
	}, [isOpen]);

	return (
		<Portal.Root>
			{isOpen && (
				<>
					<div className={style.overlay} />
					<div ref={container} className={style.container}>
						<div
							aria-modal={true}
							className={style.orientation[position]}
							ref={ref}
							role="dialogue"
							tabIndex={-1}>
							<div className={`${style.content}`}>{children}</div>
						</div>
					</div>
				</>
			)}
		</Portal.Root>
	);
}

export function DrawerHeader({ children }) {
	return (
		<div className={style.header}>
			<h4 className={style.headerTitle}>{children}</h4>
		</div>
	);
}

export function DrawerBody({ children }) {
	return <div className={style.body}>{children}</div>;
}

export function DrawerFooter({ children }) {
	return <div className={style.footer}>{children}</div>;
}

Drawer.propTypes = {
	children: reactChildren.isRequired,
	isOpen: PropTypes.bool,
	offsetClass: PropTypes.string,
	position: PropTypes.string.isRequired,
	toggle: PropTypes.func,
};

DrawerHeader.propTypes = {
	children: reactChildren.isRequired,
};

DrawerBody.propTypes = {
	children: reactChildren.isRequired,
};

DrawerFooter.propTypes = {
	children: reactChildren.isRequired,
};
